@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

*,*::before,*::after {
	margin: 0;
	font-family: "Urbanist", serif !important;
}

html {
	height: -webkit-fill-available;
	/* We have to fix html height */
}


body {
	position: relative;
	padding: 1em;

}

.recomended_users_wrapper{
	width: 100%;
	background-image: url("../images/ai_background.jpg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	padding: 1em 2em;
	margin-bottom: 2em;
	border-radius: 30px;
	position: relative;
	overflow: hidden;

}
.recomended_users_wrapper_overlay {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9;
	width: 100%;
	height: 100%;
	background: linear-gradient(to top, rgba(255, 255, 255, 1),rgba(255, 255, 255, 1),rgba(255, 255, 255, 0.98),rgba(255, 255, 255, 0.94),rgba(255, 255, 255, 0.90), transparent);
	backdrop-filter: blur(8px);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.Btn {
	width: 200px;
	height: 40px;
	border: none;
	border-radius: 40px;
	background: linear-gradient(to right,#bf953f,#fcf6ba,#b38728,#fbf5b7,#aa771c);
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	font-size: 0.8em;
	color: rgb(121, 103, 3);
	font-weight: 600;
	cursor: pointer;
	position: relative;
	z-index: 2;
	transition-duration: 3s;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.144);
	background-size: 200% 200%;
	margin-top: 10px;
}
.plan {
	background: #fff;
	border-radius: 24px;
	padding: 24px;
	box-shadow: 0 10px 30px rgba(0,0,0,0.1);
	color: #5405c2;
	transition: all 0.4s;
	cursor: pointer;
	width: 28%;
	height: fit-content;
	min-height: 480px;
}
.plan-new{
	background: #5405c2;
	color: #ffffff;
	position: relative;
	overflow: hidden;
}
.plan-new-badge{
	padding: .4em 2em;
	width: 120px;
	text-align: center;
	position:absolute;
	top:20px;
	right: -50px;
	background-color: #fff;
	color: #000;
	rotate: 45deg;
}
.plan:hover {
	background: #5405c2;
	color: #ffffff;
}

.plan-heading {
	font-size: 24px;
}

.plan-feature {
	list-style: none;
	padding: 0px;
}
.plan-feature li:before {
	content: "✓";
	margin-right: 16px;
}

.plan-feature li {
	margin-bottom: 16px;
	display: flex;
}

.plan-amount {
	font-size: 24px;
	font-weight: bold;
}

.plan-duration {
	opacity: 0.6;
}
.independent_heading{
	width: 100%;
	text-align: center;
	font-size: 50px;
	color: #000;
	font-weight: 600;
	margin-bottom: 1em;
}
.plan-choose {
	width: 100%;
	background: #8645ff;
	border-radius: 8px;
	border: none;
	color: white;
	font-size: 18px;
	margin-top: 2em;
	padding: 12px;
	cursor: pointer;
	font-weight: 600;
}
.plan-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.plan-save {
	background: white;
	border: none;
	padding: 5px;
	border-radius: 4px;
	color: #8645ff;
	font-weight: bold;
}

.plan-options {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	height: fit-content;
	min-height:70vh;
	padding: 6em 2em 2em;
	margin: 0 auto;
}
@media (min-width: 440px) {
	.plan-options {
		grid-template-columns: 1fr 1fr;
	}
}
@media (min-width: 760px) {
	.plan-options {
		grid-template-columns: repeat(4, 1fr);
	}
	.plan:hover {
		transform: scale(1.1);
	}
}


.logoIcon path {
	fill: rgb(121, 103, 3);
}

.Btn:hover {
	transform: scale(0.95);
	transition-duration: 3s;
	animation: gradient 5s ease infinite;
	background-position: right;
}


.css-1nmdiq5-menu{
	z-index: 999999 !important;
}
.app_main_content {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	z-index: 9;
}

.app_main_content::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(255,255,255,0.7);
	z-index: -1;
}

.bookmarkBtn {
	width: 200px;
	height: 40px;
	border-radius: 40px;
	border: 1px solid #905cf4;
	background: linear-gradient(12deg, #905cf4, #070197);
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition-duration: 0.3s;
	overflow: hidden;
	margin-left: 10px;
}

.IconContainer {
	width: 30px;
	height: 30px;
	background: #fff;
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	z-index: 2;
	transition-duration: 0.3s;
}

.icon {
	border-radius: 1px;
}

.text {
	height: 100%;
	width: 160px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	z-index: 1;
	transition-duration: 0.3s;
	font-size: 1.04em;
}

.bookmarkBtn:hover .IconContainer {
	width: 90px;
	transition-duration: 0.3s;
}

.bookmarkBtn:hover .text {
	transform: translate(10px);
	width: 0;
	font-size: 0;
	transition-duration: 0.3s;
}

.bookmarkBtn:active {
	transform: scale(0.95);
	transition-duration: 0.3s;
}



.role_type_selector{
	transition: 300ms ease-in-out;
	cursor: pointer;
	flex-direction: column;
}
.role_type_selector img{
	width: 30px;
	margin-bottom: 6px;
}
.role_type_selector:hover{
	background:rgb(144, 92, 244) !important;scale: 1.05;
}
.adjusted_button{
	background: linear-gradient(12deg, #905cf4, #070197);
	padding: 12px;
	width: 100%;
	outline: none;
	border: none;
	margin-top: 2em;
	border-radius: 14px;
	font-size: 16px;
	color: #fff;
	font-weight: 500;
	cursor: pointer;
}
.chat_mainHeader{
	width: 94%;
	padding: 16px 20px;
	background: #fff;
	box-shadow: 0 10px 30px rgba(0,0,0,0.1);
	position: sticky;
	top:0;
	left: 0;
	z-index: 999;
	border-radius: 20px;
	margin-bottom: 2em;
}

.chat_footer{
	width: 97%;
	border: 1px solid rgb(7, 1, 151,0.1);
	padding: 2px;
	border-radius: 16px;
	margin-top: 20px;
	background: #fff;
	box-shadow: 0 10px 30px rgba(0,0,0,0.1);

}
.chat_footer form{
	width: 100%;
}
.chat_footer input{
	width: 100%;
}
.browsing_cards_wrapper{
	width: 100%;
	height: fit-content;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	gap: 20px;
}

.profile_browsing_card{
	width: 22%;
	border: 1px solid rgba(0,0,0,0.05);
	background-color: #fff;
	padding: 10px;
	border-radius: 20px;
	transition: 400ms ease-in-out;
	position: relative;
}
.profile_browsing_card_super{
	width: 100%;
	border: 1px solid rgba(0,0,0,0.05);
	background-color: #fff;
	padding: 10px 30px;
	border-radius: 20px;
	transition: 400ms ease-in-out;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.profile_browsing_card:hover{
	scale: 1.02;
	box-shadow: 0 10px 30px rgba(0,0,0,0.05);

}
.profile_picture{
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.content-wrap {
	padding-bottom: 80px;
	/* Height of the footer */
}


.footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 80px;
	/* Height of the footer */
	width: 100%;
	background-color: rgb(245, 245, 245);
	box-shadow: 0px -2px 6px -1px rgb(0 0 0 / 20%),
		0px -4px 8px 0px rgb(0 0 0 / 14%),
		0px -1px 15px 0px rgb(0 0 0 / 12%)
}

.footerText {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/* for profile testing */

#other_pictures {
	display: flex
}

/* for image upload */

input[type=file] {
	display: none;
}

.styled-image-upload {
	cursor: pointer;
}

/* browsing profiles */

.userprofilepic {
	cursor: pointer;
}

.home_filters_wrapper{
	position: relative;
}
.home_filters_wrapper_child{
	display: flex;
}


@media(max-width: 880px){
	.browsing_cards_wrapper{
		padding: 10px;
	}
	.profile_browsing_card{
		width: 90%;
	}
	.featured_members_class{
		text-align: center;
		font-size: 20px !important;
	}
.login_wrapper{
	flex-direction: column !important;
}
	.login_wrapper img{
	display: none;
	}
	.login_wrapper_block{
		width: 90% !important;
	}
	#align_signup_container{
		flex-direction: column !important;
	}
	#align_signup_container div{
		width: 90% !important;
		margin-bottom: 10px !important;
	}
	.signup_form_container{
		width: 100% !important;
	}
}

@media (max-width: 660px) {
	.home_filters_wrapper_sub{
		flex-direction: column !important ;
	}
	.home_filters_wrapper_child{
		flex-direction: row !important;
		width: 100% !important;
		justify-content: center;align-items: center;
		margin-bottom: 6px	;
	}
	.home_filters_wrapper_child button{
		margin: 0 3px !important;
		width: 30% !important;
	}
	.home_filters_wrapper_input{
		width: 90% !important;
	}
	.home_filters_wrapper_input button{
		width: 100px !important;
	}
	.home_filters_wrapper_input_button{
		position: absolute;
		z-index: 999;
		right: 24px;
		top: -58px;
	}
	.form_chat_v2 button{
		width: 100px !important;
	}
	.recomended_users_wrapper{
		padding: 1em;
	}
}
.social-links {
    display: flex;
    justify-content: left; /* Center horizontally */
    align-items: center; /* Center vertically */
    gap: 10px; /* Space between items */
  }

  @media (min-width: 550px) { /* Desktop breakpoint */
   .social-links {
      flex-direction: row; /* Align items horizontally */
    }
  }

  @media (max-width: 549px) { /* Mobile breakpoint */
   .social-links {
      flex-direction: column; /* Stack items vertically */
    }
  }
